<template>
	<div>
		<div>
			<div id="teleports"></div>
		</div>
		<div>
			<NuxtLoadingIndicator :color="'#D59066'" />
		</div>
		<client-only>
			<div class="region_block" v-if="showPopup">
				<div class="region_text">
					<h3 class="mb-0">Looks like you’re in the {{ countryName }}</h3>
					<img src="~/assets/assets/usa.svg" width="80" height="80" alt="usd logo">
					<p class="mb-0" v-if="['US'].includes(countryCode)">
						Please Proceed to our U.S site
					</p>
					<p class="mb-0" v-else-if="!['US', 'IN'].includes(countryCode)">
						Please Proceed to our U.S site for International Shipping
					</p>
					<span @click="switchCountry">proceed</span>
				</div>
			</div>
		</client-only>
		<div>
			<CommonTheHeader/>
			<div>
				<slot />
			</div>
			<CommonTheFooter/>
		</div>
		<div>
			<!-- <SpeedInsights/> -->
		</div>
		<client-only>
			<CommonCookiePopup />
			<CommonSubscribeModal v-if="!$country.is_india" />
			<div class="wtsapp_fixed">
				<a v-if="$country.is_india" target="_blank" rel="nofollow" href="https://wa.me/9920987654">
					<img src="~/assets/assets/wtsapp.svg" width="50" height="50" alt="">
				</a>
			</div> 
		</client-only>
	</div>
</template>

<script setup>

import { useSeo } from '@/composables/seo/setMetaTags'
// import { SpeedInsights } from "@vercel/speed-insights/nuxt"
const route = useRoute()
const runtimeConfig = useRuntimeConfig();
const { $country, $PageView } = useNuxtApp()
const showPopup = ref(false)
const countryCode = ref(false)
const countryName = ref(false)


const tags = useSeo();
await tags.globalMetatags()


const switchCountry = (locale)=>{
	navigateTo('/?disable_localization=true').then(() =>{
		window.location.reload(); 
	})
	
}

const getGeolocation = () =>{
	$fetch(useCountryPath('/get-user-location'))
	.then((res) =>{
		if(res.success){
			if([false, 'false'].includes(runtimeConfig.public.regional_alert)){
				return
			}
			if(['185.183.52.150', '154.16.73.215'].includes(res.data.ipAddress)){
				return
			}
		
			if(res.data.countryCode != 'IN' && $country.location === 'IN'){
				countryCode.value = res.data.countryCode
				countryName.value = res.data.countryName
				showPopup.value = true

				let padding = window.innerWidth - document.documentElement.clientWidth;

				document.body.style.paddingRight = `${padding}px`; 
				document.body.style.overflow = 'hidden'; 
			}
		}
	})
}

watch(() => route.path, (nv,ov)=>{
	$PageView()
});

onMounted(() =>{
	$PageView()
  	getGeolocation()
})

</script>

<style>
	.page-enter-active,
	.page-leave-active {
		transition: all 0.25s ease-out;
	}

	.page-enter-from {
		opacity: 0;
		/*  transform: translateY(30px);*/
	}

	.page-leave-to {
		opacity: 0;
		/*  transform: translateY(-30px);*/
	}


	.page-group-enter-active{
		transition: opacity 0.25s ease-in-out;
	}

	.page-group-leave-active{
		display: none;
		transition: opacity 0;
	}

	.page-group-enter,
	.page-group-leave-to{
		opacity: 0;
	}

	.page-group-appear-active {
		transition: opacity 0.25s;
	}


	.page-group-appear {
		opacity: 0;
	}

	.layout-enter-active,
	.layout-leave-active {
		transition: all 0.4s;
	}
	.layout-enter-from,
	.layout-leave-to {
		filter: grayscale(1);
	}
	.wtsapp_fixed {
		position: fixed;
		bottom: 25px;
		right: 25px;
		z-index: 9999999;
	}

	.region_block {
		text-align: center;
		background: #00000085;
		color: #fff;
		font-size: 14px;
		padding: 15px 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		z-index: 9999;
	}

	.region_block span {
		background: var(--green);
		display: inline-block;
		padding: 8px 20px;
		text-transform: uppercase;
		border-radius: 4px;
		cursor: pointer;
		font-weight: 600;
		letter-spacing: 1px;
		font-size: 16px;
		margin-bottom: 25px;
	}
	.region_text {
		max-width: 350px;
		background: #fff;
		border-radius: 5px;
	}

	.region_text h3 {
		background: #eaeaea;
		font-size: 18px;
		color: #353535;
		padding: 15px 10px;
		border-radius: 5px 5px 0 0;
		text-transform: uppercase;
		letter-spacing: 1px;
		font-weight: 600;
		margin-bottom: 15px !important;
	}

	.region_text p {
		color: #353535;
		font-size: 16px;
		padding: 8px 15px 20px;
		font-weight: 600;
	}
	@media(max-width: 992px){
		.region_block span {
			margin-top: 5px;
			margin-left: 0;
		}
	}
	@media(max-width: 767px){
		.wtsapp_fixed img {
			width: 45px;
		}
		.wtsapp_fixed {
			bottom: 20px;
			right: 20px;
		}
	}
</style>
